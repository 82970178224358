<template>
  <section class="bg-brand-500 rounded-m pt-8 pb-12 px-8 md:pt-12 mt-8">
    <div class="text-center flex flex-col items-center justify-center">
      <h2 class="text-white font-poppins text-h2s md:text-h2m font-bold mb-4">{{ $t('signup.cta.title') }}</h2>
      <p class="text-white text-p2s md:text-p2m mb-6 ">{{ $t('signup.cta.text') }}</p>
      <NuxtLink class="mx-auto max-w-md bg-white text-dark-900 text-base rounded-m cursor-pointer font-bold px-8 py-3 gap-2 flex items-center" :to="localePath('/signup')">
        {{ $t('try.it.today') }}
        <SvgChevronRight class="w-6 h-6" />
      </NuxtLink>
    </div>
  </section>
</template>

<script setup >
import {useCookieStore} from "~/store/cookie";
import {storeToRefs} from "pinia";
const cookieStore = useCookieStore();
const {user} = storeToRefs(cookieStore);
</script>

<style scoped>

</style>